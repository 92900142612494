import { intersection } from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from '@apollo/client'
import { useAuth } from '@praxis/component-auth'
// import { itemCommonUtilis } from 'item-component'
import { userSessionTokenQuery } from '../apiItemGraphql/userSession'
import { bannerQuery } from '../apiItemGraphql/banner'
import { setUserProfile, setUserSession } from '../store/userProfile'
import { setBanner } from '../store/bannerHeader'
import { getUserInfo } from '../globalUtils/commonUtills'
import { mainMenus } from '../constants/menu'

export const useMain = () => {
  const dispatch = useDispatch()
  const auth = useAuth()
  const { session } = auth
  const { loading, error, data } = useQuery(userSessionTokenQuery())
  const getUserProfile = useSelector((state) => {
    return getUserInfo(state)
  })
  useEffect(() => {
    dispatch(setUserProfile(data?.userSessionToken?.token))
    if (session) {
      dispatch(setUserSession(session))
    }
  }, [data, dispatch, session])
  const { data: bannerData } = useQuery(bannerQuery())
  useEffect(() => {
    dispatch(setBanner(bannerData?.getBannerDetail?.bannerDetails))
  }, [bannerData, dispatch])

  const bannerHeader = useSelector((state) => state.bannerHeader)

  const hasRole = (userRoles, allowedRoles) => {
    return intersection(userRoles, allowedRoles).length > 0
  }
  const constructedMenu = (allowedRoles) => {
    let menus = mainMenus.filter((feature) => feature.enabled === true)
    if (getUserProfile) {
      const filteredMenu = mainMenus.map((feature) => {
        const canViewMenu =
          feature.enabled === true ||
          hasRole(getUserProfile.roles, feature?.enabled)
        return canViewMenu ? feature : null
      })
      menus = filteredMenu.filter((n) => n)
    }
    // For Itemhub exclude menus on the Grouper on sidenav
    // if (itemCommonUtilis.isItemHub) {
    //   const excludedApplications = ['Groups']
    //   return menus.filter(
    //     (menuItem) => !excludedApplications.includes(menuItem.name),
    //   )
    // }

    return menus
  }

  return {
    loading,
    error,
    data,
    bannerHeader,
    constructedMenu,
    getUserProfile,
  }
}
