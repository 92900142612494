import constants, { userRole } from '../constants/constants'
import apiConfig from '../globalConfig/apiConfig'
import { itemCommonUtilis } from 'item-component'
export const mainMenus = [
  {
    name: 'Home',
    path: '/',
    reload: false,
    iconName: 'HouseIcon',
    groups: [],
    enabled: true,
  },
  {
    name: 'Task Board',
    path: `${apiConfig.launchpad.host}${apiConfig.launchpad.page.dashboard}`,
    reload: true,
    iconName: 'WorkIcon',
    groups: [],
    enabled: [
      userRole.ADMIN,
      userRole.SUPPORT,
      userRole.MAINTAINER,
      userRole.IAUVIEWER,
      userRole.ITEMATTRIBUTEUPDATEREQUESTOR,
    ],
  },
  {
    name: 'Search',
    path: '/search',
    reload: false,
    iconName: 'SearchIcon',
    groups: [],
    enabled: [
      ...constants.USER_ROLE_GROUPINGS.ITEM_MAINTAINERS,
      userRole.VIEWER,
      userRole.IAUVIEWER,
    ],
  },
  {
    name: 'Create Item',
    path: `${apiConfig.launchpad.host}${apiConfig.launchpad.page.create}`,
    reload: true,
    iconName: 'PlusCircleIcon',
    groups: [],
    enabled: [...constants.USER_ROLE_GROUPINGS.ITEM_MAINTAINERS],
  },
  {
    name: 'Maintain',
    path: `${apiConfig.launchpad.host}${apiConfig.launchpad.page.maintain}`,
    reload: true,
    iconName: 'PencilIcon',
    groups: [],
    enabled: [...constants.USER_ROLE_GROUPINGS.ITEM_MAINTAINERS],
  },
  {
    name: 'Relationship',
    path: `${apiConfig.launchpad.host}${apiConfig.launchpad.page.relationship}`,
    reload: true,
    iconName: 'NetworkIcon',
    groups: [],
    enabled: true,
  },
  {
    name: 'Bulk Export',
    path: `${apiConfig.launchpad.host}${apiConfig.launchpad.page.bulk}`,
    reload: true,
    iconName: 'ArrowsVerticalIcon',
    groups: [],
    enabled: [userRole.ADMIN, userRole.SUPPORT, userRole.VENDOR],
  },
  {
    name: 'Item Attribute Update Request',
    path: `${apiConfig.launchpad.host}${apiConfig.launchpad.page.iau}`,
    reload: true,
    iconName: 'ClipboardIcon',
    groups: [],
    enabled: [
      userRole.ADMIN,
      userRole.SUPPORT,
      userRole.MAINTAINER,
      userRole.IAUVIEWER,
      userRole.ITEMATTRIBUTEUPDATEREQUESTOR,
    ],
  },
  {
    name: 'Mass Maintain',
    path: `${apiConfig.massMaintain.host}`,
    reload: true,
    iconName: 'AppSwitcherIcon',
    groups: [],
    enabled: [
      userRole.ADMIN,
      userRole.SUPPORT,
      userRole.MASSMAINTAINER,
      userRole.MAINTAINER,
    ],
  },
  {
    name: 'Groups',
    // path: `${apiConfig.groups.host}`,
    path: itemCommonUtilis.isItemHub
      ? `${window.location.origin}/grouper`
      : `${apiConfig.groups.host}`,
    reload: true,
    iconName: 'GroupingIcon',
    groups: [],
    enabled: [userRole.ADMIN, userRole.SUPPORT, userRole.MAINTAINER],
  },
  {
    name: 'Open Action Management',
    // path: `${apiConfig.opentask.host}`,
    path: itemCommonUtilis.isItemHub
      ? `${window.location.origin}/opentask`
      : `${apiConfig.opentask.host}`,
    reload: true,
    iconName: 'WorkspaceIcon',
    groups: [],
    enabled: [userRole.ADMIN, userRole.SUPPORT, userRole.MAINTAINER],
  },
  {
    name: 'Admin',
    path: `${apiConfig.launchpad.host}${apiConfig.launchpad.page.admin}`,
    reload: true,
    iconName: 'PersonIcon',
    groups: [],
    enabled: [userRole.ADMIN, userRole.SUPPORT],
  },
]
